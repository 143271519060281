import React, { useEffect, useState } from 'react';
import { Link } from '@fluentui/react';

import './CategoryWiseList.scss';
import { Trans } from 'react-i18next';
import NewsCard from '../newsCard/NewsCard';
import { IArticleData, ITags } from 'app/models/common/post';
import { setQueryParam } from 'app/components/helpers';
import useStore from 'app/store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';
import { getArticleCategorySvgComponent } from 'app/utils/SVGIcons';
import { BlobStorageContainer, Entity } from 'app/utils/constants';

interface IProps {
    articles: Array<IArticleData>;
    categoryName?: string;
    background?: string;
    iconColor?: string;
    title?: string;
    icon?: boolean;
    showViewAll?: boolean;
    showCardBorder: boolean;
}
interface IParams {
    id: string;
}
const CategoryWiseList: React.FC<IProps> = (props: IProps) => {
    const { articles, categoryName, background = '', iconColor, title, icon = true, showViewAll = true, showCardBorder } = props;
    const [categoryWiseNewsData, setCategoryWiseNewsData] = useState([]);
    const articleCategories: Array<ITags> = useStore((state: any) => state.articleCategories);
    const [categoryIcon, setCategoryIcon] = useState<React.ReactNode>(undefined);

    const { id: articleId } = useParams<IParams>();
    const { pathname } = useLocation();
    const history = useHistory();
    const isArticleDetail = pathname === `/manage-content/article/${articleId}`;

    useEffect(() => {
        const replaceStorageUrls = async () => {
            articles.forEach(async (article) => {
                article.thumbnail = await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, article.thumbnail);
                article.coverPhoto = await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, article.coverPhoto);
            });
        }
        replaceStorageUrls();

        let isMounted = true;
        if (isMounted) setCategoryWiseNewsData(articles);
        return () => (isMounted = false);
    }, [articles]);

    const getCategoryByName = (tagName: string) => {
        var matchingCategory = articleCategories?.find((item) => {
            return item.displayName === tagName;
        });
        return matchingCategory;
    };

    useEffect(() => {
        let iconUrl = getCategoryByName(categoryName)?.icon
        setCategoryIcon(getArticleCategorySvgComponent(iconUrl));
    }, [categoryName]);

    return (
        <>
            <div className={`category ${background} ${background ? iconColor : ''}`}>
                <div className="container">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-lg12">
                                {categoryWiseNewsData?.length >= 3 && !isArticleDetail && showViewAll && (
                                    <span className="viewall-link">
                                        <Link
                                            role="link"
                                            onClick={() =>
                                                setQueryParam(
                                                    history,
                                                    pathname.includes('/knowledge-articles') ? '/knowledge-articles' : '/news',
                                                    { tag: categoryName }
                                                )
                                            }>
                                            <Trans>View All</Trans>
                                            <i className="ms-Icon ms-Icon--ChevronRight" aria-hidden="true"></i>
                                        </Link>
                                    </span>
                                )}
                                {title && (
                                    <div className="header-container">
                                        {icon && (
                                            <span className="icon">{categoryIcon}</span>
                                        )}
                                        <h2>{title}</h2>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            {categoryWiseNewsData?.map((data) => (
                                <div key={data.id} className="ms-Grid-col ms-sm12 ms-lg4">
                                    <NewsCard
                                        className="category--card"
                                        data={data}
                                        showArticleType={false}
                                        showMoreOptions={false}
                                        showLastEdited={false}
                                        showStatus={false}
                                        border={showCardBorder}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategoryWiseList;
